@font-face {
    font-family: 'Inter', sans-serif;
    src: url("./fonts/Inter-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Inter', sans-serif;
    src: url("./fonts/Inter-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Inter', sans-serif;
    src: url("./fonts/Inter-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Inter', sans-serif;
    src: url("./fonts/Inter-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
