// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {

    //GLOBAL
    --ion-border-radius: 16px;
    --ion-font-family: 'Inter';

    // TOOLBAR
    --ion-safe-area-top: 5px;

    // BUTTONS
    --ion-button-padding-top: 15px;
    --ion-button-padding-bottom: 15px;

}


ion-button::part(native) {
    padding-top: var(--ion-button-padding-top);
    padding-bottom: var(--ion-button-padding-bottom);
}

ion-toolbar {
    --background: #fff;
}